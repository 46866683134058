@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
    /* Slanted  */
    /* @font-face { */
    /*     font-family: "PPObjectSans-Slanted"; */
    /*     font-weight: bold; */
    /*     src: url("../fonts/PPObjectSans-HeavySlanted.woff2") format("woff2"); */
    /* } */
    /* @font-face { */
    /*     font-family: "PPObjectSans-Slanted"; */
    /*     font-weight: normal; */
    /*     src: url("../fonts/PPObjectSans-MediumSlanted.woff2") format("woff2"); */
    /* } */
    /*  */
    /* @font-face { */
    /*     font-family: "PPObjectSans"; */
    /*     font-weight: 900; */
    /*     src: url("../fonts/PPObjectSans-Heavy.woff2") format("woff2"); */
    /* } */
    /* @font-face { */
    /*     font-family: "PPObjectSans"; */
    /*     font-weight: 700; */
    /*     src: url("../fonts/PPObjectSans-Bold.woff2") format("woff2"); */
    /* } */
    /* @font-face { */
    /*     font-family: "PPObjectSans"; */
    /*     font-weight: 400; */
    /*     src: url("../fonts/PPObjectSans-Medium.woff2") format("woff2"); */
    /* } */
    html {
        color: "#535D62";
    }
}
